<template>
  <v-container fluid>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-checkbox-marked-outline
      </v-icon> Check-In Details
    </h2>
    <br>
    <v-btn
      small
      color="red darken-3"
      @click="$router.push({ name: 'Check-In Records' })"
    >
      <v-icon left>
        mdi-chevron-double-left
      </v-icon>
      Back
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <v-container
        v-if="isRecordDetailsFetched"
        fluid
      >
        <v-row
          v-if="recordDetails.type === 'staff'"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  User Details
                </v-toolbar-title>
              </v-toolbar>
              <v-container
                fluid
                style="border-left:3px solid #FF3700"
              >
                <br>
                <p class="my-p-style">
                  Name:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.first_name }} {{ recordDetails.service_provider.last_name }}
                  </font>
                </p>
                <p class="my-p-style">
                  Category:-
                  <font
                    v-if="recordDetails.service_provider.contractor_category !== null"
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.contractor_category.name }}
                  </font>
                  <font
                    v-else
                    class="font-my-style"
                  >
                    N/A
                  </font>
                </p>
                <p class="my-p-style">
                  Company:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.company.company_name }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Phone Number:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.phone_number }}
                  </font>
                </p>
              </v-container>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  Check-In / Check-Out Details
                </v-toolbar-title>
              </v-toolbar>
              <v-container
                fluid
                style="border-left:3px solid #FF3700"
              >
                <br>
                <p class="my-p-style">
                  Check-In At:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                  </font>
                </p>
                <p class="my-p-style">
                  Check-In Type:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.type }}
                  </font>
                </p>
                <p class="my-p-style">
                  Check-Out At:-
                  <font
                    v-if="recordDetails.checkout_time !== null"
                    class="font-my-style"
                  >
                    {{ recordDetails.checkout_time | convertStringToLocalDatetime }}
                  </font>
                  <font
                    v-else
                    class="My-checkout-time"
                  >
                    Active*
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Total Hours:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.total_hours }} Hrs
                  </font>
                </p>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="recordDetails.type !== 'staff'"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  User Details
                </v-toolbar-title>
              </v-toolbar>
              <v-container
                fluid
                style="border-left:3px solid #FF3700"
              >
                <p class="my-heading-style">
                  Profile
                </p>
                <hr>
                <br>
                <p class="my-p-style">
                  Name:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.first_name }} {{ recordDetails.service_provider.last_name }}
                  </font>
                </p>
                <p class="my-p-style">
                  Category:-
                  <font
                    v-if="recordDetails.service_provider.contractor_category !== null"
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.contractor_category.name }}
                  </font>
                  <font
                    v-else
                    class="font-my-style"
                  >
                    N/A
                  </font>
                </p>
                <p class="my-p-style">
                  Company:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.company.company_name }}
                  </font>
                </p>
                <p
                  class="my-p-style"
                >
                  Phone Number:-
                  <font
                    class="font-my-style"
                  >
                    {{ recordDetails.service_provider.phone_number }}
                  </font>
                </p>
                <v-list>
                  <v-list-item-group>
                    <p class="my-heading-style">
                      Questionnaire
                    </p>
                    <hr>
                    <div
                      v-if="recordDetails.questionnaire.length === 0"
                      class="no-file-alert"
                    >
                      <h4>
                        No questionnaire found.
                      </h4>
                    </div>
                    <template v-for="(item, index) in recordDetails.questionnaire">
                      <v-list-item :key="'item'+ index">
                        <template>
                          <v-list-item-content class="ml-1">
                            <v-list-item-title>
                              <p
                                class="my-p-style"
                              >
                                {{ index + 1 }}. Question:  <font
                                  class="font-my-style"
                                >
                                  {{ item.question.question }}
                                </font>
                              </p>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <p
                                class="my-p-style"
                              >
                                Answer: <font
                                  class="answer-my-style"
                                >
                                  {{ item.answer }}
                                </font>
                              </p>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <font
                          class="status-style"
                          :color="getColor(item.question.deleted_at)"
                        >
                          {{ item.question.deleted_at | statusUpdate }}
                        </font>
                      </v-list-item>
                      <v-divider
                        v-if="index < recordDetails.questionnaire.length - 1"
                        :key="index"
                      />
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-container>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card>
              <v-toolbar
                color="#37474F"
                dark
              >
                <v-toolbar-title class="my-toolbar-style">
                  Check-In Details
                </v-toolbar-title>
              </v-toolbar>
              <v-container
                fluid
                style="border-left:3px solid #FF3700"
              >
                <p class="my-heading-style">
                  Check-In
                </p>
                <hr>
                <br>
                <div v-if="recordDetails.type === 'contractor'">
                  <p class="my-p-style">
                    Check-In At:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Check-In Type:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.type }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Job Number:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.job_details.job_number }}
                    </font>
                  </p>
                  <p
                    class="my-p-style"
                  >
                    High Risk Work (HRW):-
                    <font
                      :color="hrwStatusColor(recordDetails.job_details.high_risk)"
                    >
                      {{ recordDetails.job_details.high_risk | convertToString }}
                    </font>
                  </p>
                  <p
                    class="my-p-style"
                  >
                    Description:-
                    <font
                      v-if="recordDetails.job_details.description !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.job_details.description }}
                    </font>
                    <font
                      v-else
                      class="font-my-style"
                    >
                      Nill
                    </font>
                  </p>
                </div>
                <div v-if="recordDetails.type === 'visitor'">
                  <p class="my-p-style">
                    Check-In At:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Check-In Type:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.type }}
                    </font>
                  </p>
                  <p
                    class="my-p-style"
                  >
                    Visit Purpose:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.visitor_details.purpose }}
                    </font>
                  </p>
                </div>
                <p class="my-heading-style">
                  Check-Out
                </p>
                <hr>
                <br>
                <div v-if="recordDetails.type === 'contractor'">
                  <p class="my-p-style">
                    Check-Out At:-
                    <font
                      v-if="recordDetails.checkout_time !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.checkout_time | convertStringToLocalDatetime }}
                    </font>
                    <font
                      v-else
                      class="My-checkout-time"
                    >
                      Active*
                    </font>
                  </p>
                  <p class="my-p-style">
                    Job Status:-
                    <font
                      class="status"
                      :color="statusColor(recordDetails.job_details.status)"
                    >
                      {{ recordDetails.job_details.status }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Reason:-
                    <font
                      v-if="recordDetails.job_details.pending_reason !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.job_details.pending_reason }}
                    </font>
                    <font
                      v-else
                      class="font-my-style"
                    >
                      Nill
                    </font>
                  </p>
                  <p
                    class="my-p-style"
                  >
                    Total Work Hours:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.total_hours }} Hrs
                    </font>
                  </p>
                  <p
                    v-if="isOrgInvoiceEnabled"
                    class="my-p-style"
                  >
                    Invoice Amount:-
                    <font
                      v-if="recordDetails.job_details.invoice"
                      class="font-my-style"
                    >
                      {{ recordDetails.job_details.invoice }} /-
                    </font>
                    <font
                      v-else
                      class="font-my-style"
                    >
                      Nill
                    </font>
                  </p>
                </div>
                <div v-if="recordDetails.type === 'visitor'">
                  <p class="my-p-style">
                    Check-Out At:-
                    <font
                      v-if="recordDetails.checkout_time !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.checkout_time | convertStringToLocalDatetime }}
                    </font>
                    <font
                      v-else
                      class="My-checkout-time"
                    >
                      Active*
                    </font>
                  </p>
                  <p class="my-p-style">
                    Visit Status:-
                    <font
                      class="status"
                      :color="statusColor(recordDetails.visitor_details.status)"
                    >
                      {{ recordDetails.visitor_details.status }}
                    </font>
                  </p>
                  <p
                    class="my-p-style"
                  >
                    Total Visit Hours:-
                    <font
                      class="font-my-style"
                    >
                      {{ recordDetails.total_hours }} Hrs
                    </font>
                  </p>
                  <p class="my-p-style">
                    Reason:-
                    <font
                      v-if="recordDetails.visitor_details.pending_reason !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.visitor_details.pending_reason }}
                    </font>
                    <font
                      v-else
                      class="font-my-style"
                    >
                      N/A
                    </font>
                  </p>
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import ModuleHelper from 'src/helpers/module-helper';
import Constants from 'src/constants';

export default {
  name: 'CheckInRecordDetails',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    convertToString (val) {
      if (val === true) {
        return 'Yes*';
      }
      return 'No';
    },
    statusUpdate (val) {
      if (val !== null) {
        return 'Deleted';
      }
      return 'Active*';
    },
  },
  data () {
    return {
      loading: false,
      helper: new ModuleHelper(),
    };
  },
  computed: {
    recordDetails () {
      return this.$store.getters['records/getRecordDetails'];
    },
    isRecordDetailsFetched () {
      if (Object.keys(this.recordDetails).length > 0) {
        return true;
      }
      return false;
    },
    isOrgInvoiceEnabled () {
      return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
    },
  },
  async mounted () {
    this.loading = true;
    await this.fetchCheckInRecordDetails();
    this.loading = false;
  },
  methods: {
    async fetchCheckInRecordDetails () {
      const recordId = this.$route.params.recordId;
        await this.$store.dispatch('records/fetchRecordDetails', {
          record_id: recordId,
        });
    },
    statusColor (status) {
      if (status === 'completed') {
        return '#2E7D32';
      } else {
        return '#C62828';
      }
    },
    hrwStatusColor (status) {
      if (status === true) {
        return '#C62828';
      } else {
        return '#2E7D32';
      }
    },
    getColor (val) {
      if (val !== null) {
        return '#C62828';
      }
      return '#2E7D32';
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
}
.answer-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #FF3700;
  text-transform: capitalize;
}
.my-card-style {
    background-color: #ECEFF1;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-heading-style {
    font-size: 17px;
    font-weight: bold;
    color: #263238;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-toolbar-style {
    font-size: 20px;
    font-weight:500;
    text-align: center;
}
.status {
  font-weight: bold;
  font-size: 15px;
  text-transform: capitalize;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-style {
  float: right;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.no-file-alert {
  color: #C62828;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 15px;
}
</style>
